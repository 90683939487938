<template>
  <b-card class="">
    <div class="mb-6">
      <h2 v-if="active == 'market'" class="font-weight-bolder light-blue">
        {{ $t("market") }}
      </h2>
      <h2 v-else-if="active == 'open'" class="font-weight-bolder light-blue">
        {{ $t("my_invoices") }}
      </h2>
      <h2 v-else-if="active == 'paid'" class="font-weight-bolder light-blue">
        {{ $t("archive") }}
      </h2>
      <div class="col-md-12">
        <b-row>
          <b-button
            v-if="active == 'open' || active == 'paid'"
            :variant="active == 'market' ? 'primary' : 'outline-primary'"
            class="mr-1"
            @click="activeItem('market')"
          >
            {{ $t("market") }}</b-button
          >
          <b-button
            :variant="active == 'open' ? 'primary' : 'outline-primary'"
            class="mr-1"
            @click="activeItem('open')"
            >{{ $t("my_purchased_invoices") }}
          </b-button>
          <b-button
            :variant="active == 'paid' ? 'primary' : 'outline-primary'"
            class="mr-1"
            @click="activeItem('paid')"
          >
            {{ $t("invoice_archive") }}
          </b-button>
        </b-row>
      </div>
      <b-row>
        <b-col md="12" class="mb-5" v-if="invoices.length">
          <Table
            :fields="fields"
            :url="
              active == 'open'
                ? '/market/invoices/status/10'
                : active == 'paid'
                ? '/market/invoices/status/11'
                : '/market/invoices'
            "
            :showFilter="false"
            style="min-height: 30vh"
            @row-clicked="myRowClickHandler"
          >
            <template #cell(id)="data">
              <p style="width: 55px" class="badge badge-light-primary">
                I-{{ data.item.id }}
              </p>
            </template>
            <template #cell(reference)="data">
              <b-link :to="'/market/' + data.item.id">
                {{ data.item.id }}
              </b-link>
            </template>
            <template #cell(investment_amount)="data">
              <span v-if="data.item.scorings[0] && data.item.scorings[0].amount_with_discount" class="badge badge-secondary">
<!--                {{ amountFormat(CalculateInvestmentAmount(data.item.scorings[0]), data.item.currency.name) }}-->
                {{ amountFormat(data.item.scorings[0].amount_with_discount, data.item.currency.name) }}
              </span>
            </template>
            <template #cell(debtor)="data">
              <span v-if="data.item.debtor && companyDescription(data.item.debtor)">
                {{
                  companyDescription(data.item.debtor)
                }}
              </span>
              <span
                  v-else-if="
                  data.item.debtor &&
                  data.item.debtor.credit_reform &&
                  data.item.debtor.credit_reform[0] &&
                  data.item.debtor.credit_reform[0].industry
                "
              >
                <span
                    v-if="
                    isJsonString(data.item.debtor.credit_reform[0].industry)
                  "
                >
                  {{
                    JSON.parse(data.item.debtor.credit_reform[0].industry)[
                      $store.state.app.language
                    ]
                  }}
                </span>
                <span v-else>
                  {{ data.item.debtor.credit_reform[0].industry }}
                </span>
              </span>
              <span v-else>-</span>
            </template>
            <template #cell(currency)="data">
              <span
                v-html="
                  data.item && data.item.currency && data.item.currency.code
                    ? data.item.currency.code
                    : '-'
                "
              >
              </span>
            </template>
            <template #cell(seller)="data">
              <span v-if="companyDescription(data.item.company)">
                {{
                  companyDescription(data.item.company)
                }}
              </span>
              <span
                v-else-if="
                  data.item.company &&
                  data.item.company.credit_reform &&
                  data.item.company.credit_reform[0] &&
                  data.item.company.credit_reform[0].industry
                "
              >
                <span
                  v-if="
                    isJsonString(data.item.company.credit_reform[0].industry)
                  "
                >
                  {{
                    JSON.parse(data.item.company.credit_reform[0].industry)[
                      $store.state.app.language
                    ]
                  }}
                </span>
                <span v-else>
                  {{ data.item.company.credit_reform[0].industry }}
                </span>
              </span>
              <span v-else>-</span>
            </template>
            <template #cell(yield)="data">
              <span
                v-if="data.item && data.item.scorings && data.item.scorings[0]"
                >{{
                  amountFormat(data.item.scorings[0].discount_from_amount,data.item.currency.name)
                }}</span
              >
            </template>
            <template #cell(net_return)="data">
              <span
                v-if="
                  data.item &&
                  data.item.fractions_bought &&
                  data.item.fractions_bought[0]
                "
                >{{
                    amountFormat(data.item.fractions_bought[0].net_return | formatAmount,data.item.currency.name)
                }}</span
              >
            </template>
            <template #cell(roi_in_%)="data">
              <span
                v-if="data.item && data.item.scorings && data.item.scorings[0]"
              >
               {{ calculateRoiPercentage(data.item) }}
<!--               {{ RoiPercentageCalculate(data.item.scorings[0]) }}-->
              </span>
              %
            </template>
            <template #cell(rating)="data">
              <b-badge
                variant="success"
                v-if="
                  data.item && data.item.scorings && data.item.scorings.length
                "
              >
                {{ data.item.scorings[0].rating }}
              </b-badge>
              <div v-else>-</div>
            </template>
            <template #cell(amount)="data">
              <span
                class="badge badge-secondary"
                v-if="data.item && data.item.scorings[0].amount"
              >
                {{ amountFormat(data.item.scorings[0].amount,data.item.currency.name)  }}
              </span>
              <span v-else> - </span>
            </template>
            <template #cell(due_days)="data">
              <span
                style="width: 60px !important"
                v-if="data.item && data.item.due_date"
              >
                {{ dueDaysLeft(data.item.due_date) }}
              </span>
              <span v-else> - </span>
            </template>
            <template #cell(due_date)="data">
              <span
                style="width: 50px !important; font-size: 12px"
                v-if="data.item && data.item.due_date"
              >
                {{ data.item.due_date }}
              </span>

              <span v-else> - </span>
            </template>
            <template #cell(fractions)="data">
              {{ getDate(data.item) }}
            </template>
            <template #cell(progress)="data">
              <b-progress
                variant="success"
                :value="data.item.fractions"
                max="100"
                height="14px"
              />
              <span> {{ data.item.fractions }}% </span>
            </template>
            <template #cell(action)="data">
              <b-badge
                v-if="data.item.fractions != 100"
                style="padding: 12px"
                class="pointer"
                variant="primary"
                >{{ $t("buy") }}</b-badge
              >
            </template>
          </Table>
        </b-col>
      </b-row>
    </div>
    <ApproveModal :textContent="modalText" :title="modalTitle" />
  </b-card>
</template>
<script>
import Table from "@/components/common/Table.vue";
import ApproveModal from "@/components/common/ApproveModal";
import i18n from "@/libs/i18n";
import { CurrencyAmountFormat } from '@core/utils/CurrencyAmountFormat';

export default {
  components: { Table, ApproveModal },
  data() {
    return {
      invoices: [],
      fields: [],
      approveModalAction: null,
      modalText: "",
      modalTitle: "",
      invoice: null,
      items: [],
      active: "market",
    };
  },
  created() {
    this.fillFieldsVar();
    this.getInvoices();
  },
  methods: {

    // RoiPercentageCalculate(scoring) {
    //
    //   return Number(
    //       scoring.discount_in_percentage * 1 - scoring.fee_hive * 1
    //       // scoring.discount_in_percentage
    //   ).toFixed(2);
    // },

    // CalculateInvestmentAmount(scoring) {
    //   let roi_percent = scoring.discount_in_percentage - scoring.fee_hive;
    //   let roi = (roi_percent/100) * scoring.amount;
    //   console.log(scoring.amount_with_discount + roi, 'barev')
    //   return scoring.amount_with_discount * 1 + roi * 1;
    // },

    amountFormat(amount, currency) {
      return CurrencyAmountFormat(amount, currency)
    },
    isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    fillFieldsVar(investment = 0) {
      this.fields = [
        {
          label: "id",
          align: "start",
          key: "id",
          sortable: true,
        },
        {
          label: "seller_industry",
          align: "start",
          key: "seller",
          sortable: false,
        },
        {
          label: "debtor_industry",
          align: "start",
          key: "debtor",
          sortable: false,
        },
        { label: "rating", align: "rating", key: "rating", sortable: false },

        { label: "currency", align: "start", key: "currency", sortable: false },
        {
          label: "investment_amount",
          align: "start",
          key: "investment_amount",
          tdClass: "text-right",
          thClass: "text-right",
        },
        {
          label: "roi_in_%",
          align: "start",
          key: "roi_in_%",
          sortable: false,
        },
        {
          label: "due_days",
          align: "start",
          key: "due_days",
          sortable: false,
        },
        { label: "funding_status", key: "progress", sortable: false },
        { label: "action", align: "start", key: "action", sortable: false },
      ];
      if (investment == 1) {
        this.fields = [
          {
            label: "id",
            align: "start",
            key: "id",
            sortable: true,
          },
          {
            label: "seller_industry",
            align: "start",
            key: "seller",
            sortable: false,
          },

          {
            label: "debtor_industry",
            align: "start",
            key: "debtor",
            sortable: false,
          },
          { label: "rating", align: "rating", key: "rating", sortable: false },

          {
            label: "currency",
            align: "start",
            key: "currency",
            sortable: false,
          },
          {
            label: "investment_amount",
            align: "start",
            key: "investment_amount",
            tdClass: "text-right",
            thClass: "text-right",
          },
          {
            label: "roi_in_%",
            align: "start",
            key: "roi_in_%",
            sortable: false,
          },
          {
            label: "net_return",
            align: "start",
            key: "net_return",
            sortable: false,
          },
          {
            label: "due_date",
            align: "start",
            key: "due_date",
            sortable: false,
          },
          { label: "settlement_status", key: "fractions", sortable: false },
          { label: "action", align: "start", key: "action", sortable: false },
        ];
      }
    },
    companyDescription(company) {

      let company_description = company.description;

      if(company_description == null) {
        return false;
      }

      if(!this.isJsonString(company_description)) {
        return false;
      }

      company_description = JSON.parse(company_description);
      if (company_description && company_description.hasOwnProperty(this.$store.state.app.language)) {
          return company_description[this.$store.state.app.language];
      }

      return false;
    },
    activeItem(active) {
      this.active = active;
      let status = "";
      this.invoices = [];
      if (active == "open") {
        this.fillFieldsVar(1);
        status = 10;
        this.load = true;
        this.$http.post("/market/invoices/status/" + status).then((res) => {
          if (res) {
            this.invoices = res.data.value.data;
            this.load = false;
          }
        });
      } else if (active == "paid") {
        this.fillFieldsVar(1);
        status = 11;
        this.load = true;
        this.$http.post("/market/invoices/status/" + status).then((res) => {
          if (res) {
            this.invoices = res.data.value.data;
            this.load = false;
          }
        });
      } else {
        this.fillFieldsVar(0);
        this.getInvoices();
      }
    },
    myRowClickHandler(record, index) {
      this.$router.push("/market/" + record.id);
    },
    changeModalInfo(action, title, text, item) {
      this.approveModalAction = action;
      this.modalText = text;
      this.modalTitle = title;
      this.invoice = item;
    },
    callApproveModalAction() {},
    getDate(item) {
      if (item.status == 11) {
        return i18n.t("settled");
      } else {
        var currentDate = new Date();
        var yourDate = new Date(item.due_date);
        if (item.status == 11) {
          return i18n.t("settled");
        } else if (yourDate < currentDate) {
          return i18n.t("overdue");
        } else if (yourDate > currentDate) {
          return i18n.t("Open");
        }
      }
    },
    getInvoices() {
      this.load = true;
      this.$http.post("/market/invoices").then((res) => {
        if (
          typeof res.data.value.data != "undefined" &&
          res.data.value.data.length > 0
        ) {
          this.invoices = res.data.value.data;
        }
        this.load = false;
      });
    },
  },
};
</script>
